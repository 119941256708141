.eventWrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	justify-content: space-around;
	.event__sessionText {
		display: flex;
		align-items: center;
		font-weight: 400;
		font-size: 18px;
		text-align: center;
	}
	.event__clickArea {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 315px;
    width: 100%;
    z-index: 75;
	}
	.event__ripple {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		width: 100%;
		height: 100%;
		background-color: rgba(128, 128, 128, 0.3);
		border-radius: 50%;
		animation: ripple-animation 2s infinite;
		z-index: 0;
	}
	.event__ripple:nth-child(2) {
		
		animation-delay: 1s;
	  }
	  
	  .clicker__ripple:nth-child(3) {
		animation-delay: 2s;
	  }
	  
	  @keyframes ripple-animation {
		0% {
		  transform: scale(0.8);
		  opacity: 0;
		}
		100% {
		  transform:  scale(1.5);
		  opacity: 1;
		}
	  }
	.event__clickValue {
		display: block;
		align-items: center;
		flex-direction: row;
		img {
			width: 50px;
		}
	}
	.event__clickerHero {
		display: flex;
		align-self: center;
		justify-content: center;
		height: 100%;
		width: 100%;
		z-index: 5;
		position: relative;
	}
	.event__progressBox {
		display: flex;	
		position: absolute;
		height: 90%;
		width: 90%;
	}
	.event__session {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		font-family: "Kumbh";
		border-radius: 44px;
		padding: 10px 15px;
		margin-bottom: -25px;
		background-color: var(--yellow);
		overflow: hidden;
		min-width: fit-content;
		min-height: 36px;
		z-index: 100
	}
	.event__seshCount {
		display: flex;
		align-items: center;
		flex-direction: row;
	}
	.event__seshProgr {
		display: flex;
		align-items: center;
		flex-direction: row;
	}
	.event__sessionProgress {
		display: flex;
		align-items: center;
		font-family: "Lacquer";
		margin-left: 5px;
		font-weight: 400;
		font-size: 18px;
		text-transform: lowercase;
	}
	.event__tip {
		display: flex;
		align-items: center;
		text-align: center;
		font-family: "Kumbh";
		margin-top: 10px;
		font-weight: 400;
		font-size: 16px;
		color: rgba(0, 0, 0, 0.5);
	}
	@media (max-height: 686px) {
		.event__clickArea {
			height: 237px;
			width: 100%;
		}
	}
}
.blackLine {
	height: 1px;
	width: 95%;
	background-color: var(--black);
}
